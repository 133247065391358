<template>
  <div style="width: 100%">
    <vs-table max-items="10" pagination :data="masterMerge">
      <template slot="thead">
        <vs-th>Date</vs-th>
        <vs-th>Utilization(%)</vs-th>
        <!-- <vs-th>Activity(%)</vs-th>  -->
        <vs-th>Visitors(%)</vs-th>
        <vs-th>Gmeet(%)</vs-th>
        <vs-th>Enrollments</vs-th>
        <vs-th>Total</vs-th>
        <vs-th>Total Calls | Total Unique Calls | Duration | Incoming | Outgoing</vs-th>
        <!-- <vs-th>Total F2F | Face To Face | GMeet | LMS Training | Online Class | Physical Meet</vs-th> -->
        <vs-th v-if="selected_team == 'CM' || logged_in_team == 'CM'">Connected Calls | Unique Calls | MWB EWB Unique Calls</vs-th>
        <vs-th v-if="selected_team != 'CM' && logged_in_team != 'CM'">Connected Calls | MWB EWB Unique Calls</vs-th>
        <!-- <vs-th>MWB Call Count</vs-th>
        <vs-th>EWB Call Count</vs-th> -->
        <!-- <vs-th>Untracked Incoming Calls | Outgoing Calls</vs-th>
        <vs-th>Missed Calls</vs-th> -->
        <vs-th>MWB Call Count | MWB Connected Calls | Total Unique Calls</vs-th>
        <vs-th>EWB Call Count | EWB Connected Calls | Total Unique Calls</vs-th>
        <vs-th>Long Duration Calls 30-60 Mins | > 60 Mins</vs-th>
        <vs-th>Total NUC | Duration</vs-th>
        <vs-th v-if='selected_team == "SR"'>Total EWB Calls | Unique Connected Calls | Incentives Approved | Recording Available (enrollments greater than 30days) </vs-th>
        <vs-th v-if='selected_team == "SR"'>Total EWB Calls | Unique Connected Calls | Incentives Approved | Recording Available (enrollments less than or equals to 30days)</vs-th>
        <vs-th>Attendance</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="index" v-for="(item,index) in data">
          <vs-td :data="item.spoc_name">
            <div>{{ item.date }}</div>
          </vs-td>

          <vs-td :data="item.call_utilization">
            <div>{{ item.call_utilization }}%</div>
          </vs-td>

          <!-- <vs-td :data="item.activity_sum">
            <div>{{ item.activity_sum }}%</div>
          </vs-td> -->

          <vs-td :data="item.visitor_utilization">
            <div>
              {{ item.visitor_utilization }} |
              {{ item.visit_engagements.length }}
            </div>
          </vs-td>

          <vs-td :data="item.gmeet_utilization">
            <div>{{ item.gmeet_utilization }}% |
              {{ item.gmeet_data.length }}</div>
          </vs-td>

          <vs-td>
            <div>
              <a
                href="#"
                @click="
                  EmitEvent('utilization-enrollment', [
                    item.enrollments,
                    item.enrollments.length,
                    item.spoc_name,
                  ])
                "
                >{{ item.enrollments.length }}</a
              >
            </div>
          </vs-td>

          <vs-td :data="item.total_utilization">
            <vs-chip :color="item.total_color">{{
              item.total_utilization
            }}</vs-chip>
          </vs-td>

          <vs-td :data="item.total_calls">
            <div>
              {{ item.total_calls }} | {{ item.total_unique_calls }} |
              {{ item.total_duration }} | {{ item.incoming_call_count }} |
              {{ item.outgoing_call_count }}
            </div>
          </vs-td>
          <!-- <vs-td :data="item.f2f">
            <div>
              <a @click="openActivityPopup('f2f',item.f2f,item.date)">{{ item.f2f }}</a> | <a @click="openActivityPopup('face_to_face',item.face_to_face,item.date)">{{ item.face_to_face }}</a> |
              <a @click="openActivityPopup('g_meet',item.g_meet,item.date)">{{ item.g_meet }}</a> | <a @click="openActivityPopup('lms_training',item.lms_training,item.date)">{{ item.lms_training }}</a> |
              <a @click="openActivityPopup('online_class',item.online_class,item.date)">{{ item.online_class }}</a> | <a @click="openActivityPopup('physical_meet',item.physical_meet,item.date)">{{ item.physical_meet }}</a>
            </div>
          </vs-td> -->

          <vs-td v-if="selected_team == 'CM' || logged_in_team == 'CM'" :data="item.cm_connected_calls">
            <div>
              <a
                href="#"
                @click="
                  EmitEvent('utilization-mwb', [
                    item.cm_connected_calls_arr,
                    item.spoc_name,
                    item.cm_connected_calls,
                    'CM Connected Calls'
                  ])
                "
                >{{ item.cm_connected_calls }} </a
              > | {{item.unique_cm_connected_calls}} | {{item.total_mwb_ewb_unique_calls}}
            </div>
          </vs-td>
          <vs-td v-if="selected_team != 'CM' && logged_in_team != 'CM'" :data="item.connected_calls">
            <div>
              <a
                href="#"
                @click="
                  EmitEvent('utilization-mwb', [
                    item.connected_calls_arr,
                    item.spoc_name,
                    item.connected_calls,
                    'Connected Calls'
                  ])
                "
                >{{ item.connected_calls }} </a
              > | {{item.total_mwb_ewb_unique_calls}}
            </div>
          </vs-td>
          <!-- <vs-td :data="item.mwb_call_count">
            <div>
              <a
                href="#"
                @click="
                  EmitEvent('utilization-mwb', [
                    item.mwb_arr,
                    item.spoc_name,
                    item.mwb_call_count,
                  ])
                "
                >{{ item.mwb_call_count }}</a
              >
            </div>
          </vs-td>
          <vs-td :data="item.ewb_call_count">
            <div>
              <a
                href="#"
                @click="
                  EmitEvent('utilization-ewb', [
                    item.ewb_arr,
                    item.spoc_name,
                    item.ewb_call_count,
                  ])
                "
                >{{ item.ewb_call_count }}</a
              >
            </div>
          </vs-td> -->

          <!-- <vs-td :data="item.untracked_in_calls_count">
            <div>
              <a
                href="#"
                @click="
                  EmitEvent('utilization-untracked', [
                    item,
                    'INCOMING',
                    item.spoc_name,
                    item.untracked_in_calls_count,
                  ])
                "
                >{{ item.untracked_in_calls_count }}</a
              >
              - {{ item.untracked_in_calls_duration }} |
              <a
                href="#"
                @click="
                  EmitEvent('utilization-untracked', [
                    item,
                    'OUTGOING',
                    item.spoc_name,
                    item.untracked_out_calls_count,
                  ])
                "
                >{{ item.untracked_out_calls_count }}</a
              >
              - {{ item.untracked_out_calls_duration }}
            </div>
          </vs-td> -->

          <!-- <vs-td :data="item.missed_call_count">
            <div>
              <a
                href="#"
                @click="
                  EmitEvent('utilization-missed', [
                    item.missed_calls_arr,
                    item.spoc_name,
                    item.missed_call_count,
                  ])
                "
                >{{ item.missed_call_count }}</a
              >
            </div>
          </vs-td> -->

          <!-- MWB Call Count|Connected Call|Unique Call -->
          <vs-td :data="item.mwb_call_count">
            <div>
              <a
                href="#"
                @click="
                  EmitEvent('utilization-mwb', [
                    item.mwb_arr,
                    item.spoc_name,
                    item.mwb_call_count,
                    'MWB Call Count'
                  ])
                "
                >{{ item.mwb_call_count }}</a
              > |
              {{ item.mwb_connected_call_count }}
              <!-- <a
                href="#"
                @click="
                  EmitEvent('utilization-mwb', [
                    item.connected_calls_arr,
                    item.spoc_name,
                    item.connected_calls,
                    'MWB Connected Calls'
                  ])
                "
                >{{ item.connected_calls }}</a>  -->
                | {{ item.total_mwb_unique_calls }}
            </div>
          </vs-td>

          <!-- EWB Call Count|Connected Call|Unique Call -->
          <vs-td :data="item.ewb_call_count">
            <div>
              <a
                href="#"
                @click="
                  EmitEvent('utilization-ewb', [
                    item.ewb_arr,
                    item.spoc_name,
                    item.ewb_call_count,
                    'EWB Call Count'
                  ])
                "
                >{{ item.ewb_call_count }}
              </a> |
              {{ item.ewb_connected_call_count }}
              <!-- <a
                href="#"
                @click="
                  EmitEvent('utilization-mwb', [
                    item.ewb_connected_calls_arr,
                    item.spoc_name,
                    item.ewb_connected_calls,
                    'EWB Connected Calls'
                  ])
                "
                >{{ item.ewb_connected_calls }}</a>  -->
              | {{ item.total_ewb_unique_calls }}
            </div>
          </vs-td>

          <vs-td :data="item.call_30_60">
            <div>
              <a href="#" @click="EmitEvent('utilization-long', [item.call_30_60_arr,item.spoc_name,item.call_30_60])">{{
                item.call_30_60
              }}</a>
              |
              <a href="#" @click="EmitEvent('utilization-long', [item.call_gt_60_arr,item.spoc_name,item.call_gt_60])">{{
                item.call_gt_60
              }}</a>
            </div>
          </vs-td>

          <vs-td :data="item.nuc_count">
            <div>
              <a
                href="#"
                @click="
                  EmitEvent('utilization-nuc', [
                    item.nuc_arr,
                    item.spoc_name,
                    item.nuc_count,
                  ])
                "
                >{{ item.nuc_count }}</a
              >
              | {{ item.nuc_duration }}
            </div>
          </vs-td>
          <vs-td v-if='selected_team == "SR"'>
            <div v-if="'enrollments_greater_than_thirty' in item">
              <a href="#" @click="srIncentiveRaw(item, 'total_ewb_calls', 'enrollments>30days')"> {{ item.enrollments_greater_than_thirty.total_ewb_calls }} </a> |
              <a href="#" @click="srIncentiveRaw(item, 'unique_calls', 'enrollments>30days')"> {{ item.enrollments_greater_than_thirty.unique_connected_calls }}</a> |
              <a href="#" @click="srIncentiveRaw(item, 'incentives', 'enrollments>30days')">{{ item.enrollments_greater_than_thirty.incentives_approved }} </a> |
              <a href="#" @click="srIncentiveRaw(item, 'call_recording_count', 'enrollments>30days')"> {{ item.enrollments_greater_than_thirty.recording_available }} </a>
            </div>
          </vs-td>
          <vs-td v-if='selected_team == "SR"'>
            <div v-if="'enrollments_less_than_equals_thirty' in item">
              <a href="#" @click="srIncentiveRaw(item, 'total_ewb_calls', 'enrollments<=30days')"> {{ item.enrollments_less_than_equals_thirty.total_ewb_calls }} </a> |
              <a href="#" @click="srIncentiveRaw(item, 'unique_calls', 'enrollments<=30days')"> {{ item.enrollments_less_than_equals_thirty.unique_connected_calls }}</a> |
              <a href="#" @click="srIncentiveRaw(item, 'incentives', 'enrollments<=30days')">{{ item.enrollments_less_than_equals_thirty.incentives_approved }} </a> |
              <a href="#" @click="srIncentiveRaw(item, 'call_recording_count', 'enrollments<=30days')"> {{ item.enrollments_less_than_equals_thirty.recording_available }} </a>
            </div>
          </vs-td>
          <vs-td>
            <div>
              {{ giveAttendanceTitle(item) }}
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-popup classContent="popup-example" class="popup-width1" :title="`${popup_date} | ${popup_titl} `" :active.sync="open_activity_popup">
      <div style="overflow-y:scroll;max-height:600px">
        <vs-table  :data="popup_data">
          <template slot="thead">
            <vs-th>
              Spoc Name
            </vs-th>
            <vs-th>
              Talk Time Utilization
            </vs-th>
            <vs-th>
              Start Time
            </vs-th>
            <vs-th>
              End Time
            </vs-th>
            <vs-th>
              Duration<br>
              &nbsp;( min )
            </vs-th>
            <vs-th>
              File Name
            </vs-th>
            <vs-th>
              Status
            </vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
              <vs-td :data="data[indextr].spoc_name">
                {{data[indextr].spoc_name}}
              </vs-td>

              <vs-td :data="data[indextr].id">
                {{data[indextr].talk_time_utilization}}
              </vs-td>

              <vs-td :data="data[indextr].id">
                {{getTime(data[indextr].start_time)}}
              </vs-td>

              <vs-td :data="data[indextr].id">
                {{getTime(data[indextr].end_time)}}
              </vs-td>
              <vs-td :data="data[indextr].id">
                {{data[indextr].duration}}
              </vs-td>
              <vs-td :data="data[indextr].id">
                <!-- {{data[indextr].file_name}} -->
                <vs-button v-if="data[indextr].file_name != null"
                color="#958cf4"
                icon="remove_red_eye"
                @click="getPreviewSrc(data[indextr].file_name,data[indextr].id)"
                ></vs-button>
              </vs-td>
              <vs-td :data="data[indextr].id">
                {{data[indextr].status}}
              </vs-td>
            </vs-tr>
          </template>

        </vs-table>
      </div>
    </vs-popup>
    <vs-popup
      style="z-index: 53001"
      fullscreen
      :active.sync="table_preview_popup"
      :title="`${popup_spoc} | ${popup_titl} | ${popup_date}`"
    >
      <div style="display: flex; justify-content: center">
        <img :src="table_preview_image" width="1000" />
      </div>
    </vs-popup>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import EventBus from "../eventbus.js";
import ApiServices from "../../ApiServices";
export default {
  props: {
    users: {
      required: true,
      type: Object,
    },
    selected_team:{
      required: true
    },
    activity:{
      required: true
    },
    logged_in_team:{
      required: true
    },
  },
  data() {
    return {
      table_preview_popup: false,
      table_preview_image:"",
      open_activity_popup: false,
      popup_data:[],
      headers:{'f2f':'F2F','face_to_face':'Face To Face','g_meet':'G Meet','lms_training':'LMS Training','online_class':'Online Class', "physical_meet":'Physical Meet'},
      popup_titl:"",
      popup_date: '',
      popup_spoc: '',
      all_call_logs: [],
      gmeet: [],
      visit_engagments: [],
      training: [],
      masterMerge: [],
      spoc_id: "",
    };
  },
  mounted() {
    console.log("this.usersss",this.users);
    this.spoc_id = this.users.spoc_id;
    this.all_call_logs = this.users.all_call_logs;
    this.gmeet = this.users.gmeet;
    this.visit_engagments = this.users.visit_engagements;
    console.log("this.visit_engagments", this.users);
    this.training = this.users.training;
    this.sortCallLogsByDay();
  },
  methods: {
    openActivityPopup(val,count,date){
      this.popup_data = []
      if (count>0) {
        let slots = this.users[[val]].filter(e => e.length);
        slots.forEach((slot) => {
          for (let i = 0; i < slot.length; i++) {
            const element = slot[i];
            if (moment(element.created_at).format('DD-MMM-YYYY') == date) {
              this.popup_data.push(element)
            }
          }
        });
        this.popup_date = moment(this.popup_data[0].created_at).format('DD MMM YYYY');
        this.popup_spoc = this.popup_data[0].spoc_name
        this.popup_titl = this.headers[val]
        this.open_activity_popup = true
      }
    },
    getTime(date){
      return moment.unix(date).format('h:mm a');

    },
    getPreviewSrc(val,id){
      this.table_preview_image = ""
      axios
        .get(`https://miles-sr.2x2.ninja/api/viewSrTeamUtilization?id=${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("imagesrc", response.data);
          this.table_preview_image = response.data;
          this.table_preview_popup = true;
        })
        .catch((error) => {
          this.handleError(error);
        })
      },
    srIncentiveRaw(item, row_category, row_days_classification){
      console.log("item, category",item, item.date, moment(item.date).format("YYYY-MM-DD"));

      let date = new Date(item.date);
      let utc = Date.UTC(
            Math.abs(date.getFullYear()),
            date.getMonth(),
            date.getDate()
        );
      let obj = {
        start_date : moment(utc).format("YYYY-MM-DD"),
        end_date : moment(utc).format("YYYY-MM-DD"),
        spoc_id : this.spoc_id,
        days_classification : row_days_classification,
        category : row_category
      }
      console.log("srIncentiveRaw",obj);
      ApiServices.srIncentiveRaw(obj)
        .then((response) => {
          console.log("srIncentiveRaw response",response);
                this.EmitEvent('utilization-ewb', [
                 response.data,
                 item.spoc_id,
                 response.data.length,
                 'EWB Call Count'
                ])

        })
        .catch((error) => {
          console.log("srIncentiveRaw error",error);
        });
    },
    giveAttendanceTitle(item) {
      let status = "";
      this.users.attendance.forEach((attendance) => {
        // attendance.formatted = moment(attendance.created_at).format("DD-MMM-YYYY");
        attendance.formatted = moment
          .unix(attendance.attendance_date)
          .format("DD-MMM-YYYY");
      });
      this.users.attendance.forEach((attendance) => {
        if (attendance.formatted === item.date) {
          status = attendance.status;
        }
      });
      return this.formattedAttendance(status);
    },
    formattedAttendance(word) {
      let replced = word.replace(/_/g, " ");
      let formatted = replced
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
      return formatted;
    },
    sortCallLogsByDay() {
      this.all_call_logs.forEach((data) => {
        data.sortDate = this.convertTimestampToDate(data.time);
      });
      this.gmeet.forEach((data) => {
        data.sortDate = this.convertTimestampToDate(data.scheduled_date);
      });
      this.visit_engagments.forEach((data) => {
        data.sortDate = data.created_at.split(" ")[0];
        data.sortDate = moment(data.sortDate, "YYYY-MM-DD").format(
          "DD-MMM-YYYY"
        );
        // console.log(data.sortDate);
      });
      this.training.forEach((data) => {
        data.sortDate = data.created_at.split(" ")[0];
      });
      // console.log(this.all_call_logs);
      this.createDayObjects();
    },
    createDayObjects() {
      // console.log(this.users);
      let call_obj = [];
      let gmeet_obj = [];
      let visit_obj = [];
      let training_obj = [];
      var unique = this.all_call_logs
        .map((name) => {
          return {
            count: 1,
            name: name.sortDate,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          let itemObj = {
            date: key,
            data: [],
          };
          call_obj.push(itemObj);
        }
      }
      call_obj.forEach((dateObj) => {
        this.all_call_logs.forEach((data) => {
          if (data.sortDate === dateObj.date) {
            dateObj.data.push(data);
          }
        });
      });

      var unique_gmmet = this.gmeet
        .map((name) => {
          return {
            count: 1,
            name: name.sortDate,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key4 in unique_gmmet) {
        if (unique_gmmet.hasOwnProperty(key4)) {
          let itemObj = {
            date: key4,
            data: [],
          };
          gmeet_obj.push(itemObj);
        }
      }
      gmeet_obj.forEach((dateObj) => {
        this.gmeet.forEach((data) => {
          if (data.sortDate === dateObj.date) {
            dateObj.data.push(data);
          }
        });
      });

      var unique2 = this.visit_engagments
        .map((name) => {
          return {
            count: 1,
            name: name.sortDate,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key2 in unique2) {
        if (unique2.hasOwnProperty(key2)) {
          let itemObj = {
            date: key2,
            data: [],
          };
          visit_obj.push(itemObj);
        }
      }
      visit_obj.forEach((dateObj) => {
        this.visit_engagments.forEach((data) => {
          if (data.sortDate === dateObj.date) {
            dateObj.data.push(data);
          }
        });
      });
      var unique3 = this.training
        .map((name) => {
          return {
            count: 1,
            name: name.sortDate,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key3 in unique3) {
        if (unique3.hasOwnProperty(key3)) {
          let itemObj = {
            date: key3,
            data: [],
          };
          training_obj.push(itemObj);
        }
      }
      training_obj.forEach((dateObj) => {
        this.training.forEach((data) => {
          if (data.sortDate === dateObj.date) {
            dateObj.data.push(data);
          }
        });
      });
      // console.log(call_obj);
      // console.log(visit_obj);
      // console.log(training_obj);
      this.mergeAllSections(call_obj, gmeet_obj, visit_obj, training_obj);
      // this.calculateUtilization(obj);
    },
    mergeAllSections(call_obj, gmeet_obj, visit_obj, training_obj) {
      call_obj.forEach((call) => {
        let obj = {
          date: call.date,
        };
        this.masterMerge.push(obj);
      });
      gmeet_obj.forEach((gmeet) => {
        let obj = {
          date: gmeet.date,
        };
        this.masterMerge.push(obj);
      });
      visit_obj.forEach((visit) => {
        let obj = {
          date: visit.date,
        };
        if (this.findIndexinMaster(obj) === -1) {
          this.masterMerge.push(obj);
        }
      });
      training_obj.forEach((training) => {
        let obj = {
          date: training.date,
        };
        if (this.findIndexinMaster(obj) === -1) {
          this.masterMerge.push(obj);
        }
      });
      this.masterMerge.forEach((master) => {
        master.all_call_logs = [];
        master.gmeet_data = [];
        master.visit_engagements = [];
        master.training = [];
      });
      call_obj.forEach((call) => {
        this.insertRespectiveFields(call, "call");
      });
      gmeet_obj.forEach((gmeet) => {
        this.insertRespectiveFields(gmeet, "gmeet");
      });
      visit_obj.forEach((visit) => {
        this.insertRespectiveFields(visit, "visit");
      });
      training_obj.forEach((train) => {
        this.insertRespectiveFields(train, "training");
      });
      const ids = this.masterMerge.map(({ date }) => date);
      this.masterMerge = this.masterMerge.filter(({ date }, index) => !ids.includes(date, index + 1));
      this.masterMerge.sort((a, b) => new Date(a.date) - new Date(b.date));
      console.log("masterMergeer", this.masterMerge);
      this.calculateUtilization(this.masterMerge);
    },
    insertRespectiveFields(obj, type) {
      this.masterMerge.forEach((master) => {
        if (master.date === obj.date) {
          if (type === "call") {
            master.all_call_logs = obj.data;
          }else if (type === "gmeet") {
            master.gmeet_data = obj.data;
          } else if (type === "visit") {
            master.visit_engagements = obj.data;
          } else if (type === "training") {
            master.training = obj.data;
          }
        }
      });
    },
    getdataa() {
      let array1= ['f2f','face_to_face','g_meet','lms_training','online_class', 'physical_meet']
      for (let i = 0; i < array1.length; i++) {
        let users =  this.users[array1[i]].filter(e => e.length);
        if (this.masterMerge.length < 0) {
          let data = 0
          let obj;
          users.forEach((user) => {
            data = user.length;
            obj = {
              'date' : moment(user[0].created_at).format('DD-MMM-YYYY'),
              [array1[i]] : data
            }
          });
            this.masterMerge.push(obj)
        }else{
          this.masterMerge.forEach((master) => {
            let data = 0
            users.forEach((user) => {
              if(moment(user[0].created_at).format('DD-MMM-YYYY') == master.date){
                data = user.length;
              }
            });
            master[array1[i]] = data;
          });

        }
      }
      this.getActivityUtilization();
    },
    findIndexinMaster(obj) {
      let returnindex = -1;
      this.masterMerge.forEach((master,index) => {
        if (master.date === obj.date) {
          returnindex = index;
        }
      });
      return returnindex;
    },
    convertTimestampToDate(timestamp) {
      let date = moment.unix(timestamp / 1000).format("DD-MMM-YYYY");
      return date;
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    timeConvert(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      return (
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2)
      );
    },
    getTrainingPercentage(data) {
      let minutes = 0;
      data.forEach((datapoint) => {
        minutes = minutes + datapoint.minutes;
      });
      // console.log(minutes);
      let percentage = (minutes / 60) * 12.5;
      return Math.round(percentage);
    },
    calculateUtilization(all_call_logs) {
      console.log("all_call_logs",all_call_logs);
      for (var i = 0; i < all_call_logs.length; i++) {
        var call_duration_sec = 0;
        var gmeet_duration_sec = 0;
        var total_calls = 0;
        // var all_call_logs = all_call_logs[i].all_call_logs;
        var business_calls_array = [];
        var mwb_call_count = 0;
        var mwb_connected_call_count = 0;
        var ewb_connected_call_count = 0;
        var ewb_call_count = 0;
        var untracked_in_calls_count = 0;
        var untracked_in_calls_duration = 0;
        var untracked_out_calls_count = 0;
        var untracked_out_calls_duration = 0;
        var missed_call_count = 0;
        var call_30_60 = 0;
        var call_gt_60 = 0;
        var total_color = "";
        var nuc_count = 0;
        var nuc_duration = 0;
        var training_utilization = 0;
        var mwb_arr = [];
        var ewb_arr = [];
        var missed_calls_arr = [];
        var nuc_arr = [];
        var enrollments = [];
        let cm_connected_calls_arr = [];
        let connected_calls = 0;
        let cm_connected_calls = 0;
        let connected_calls_arr = [];
        var cm_unique_connected_calls = 0;
        let incoming_call_count = 0;
        let outgoing_call_count = 0;
        let ewb_connected_calls = 0;
        let ewb_connected_calls_arr = [];
        let ewb_business_calls_array = [];
        let mwb_connected_calls = 0;
        let mwb_connected_calls_arr = [];
        let call_30_60_arr = [];
        let call_gt_60_arr = [];
        let ewb_levels = ['M7', 'M8', 'M9', 'M10', 'M7-', 'M8-', 'M9-', 'M7-', 'M7X', 'M7#', 'M7+', 'M7-JJ', 'M8+', 'M7D1', 'M7D2'];
        let mwb_business_calls_array = [];
        // var average = 0;
        // var visitaverage = 0;
        // console.log("passed");
        // console.log(all_call_logs[i]["all_call_logs"]);
        // all_call_logs[i]["visit_engagements"].forEach((visit_engagement) => {
        //   if (visit_engagement.visit_status === "Enrollment") {
        //     enrollments.push(visit_engagement);
        //   }
        // });
        // console.log(all_call_logs[i]["visit_engagements"]);
        // enrollments = all_call_logs[i]["visit_engagements"];
        this.users.actual_enrollments.forEach(element => {
            let actual_enrollment_date = moment.unix(element.enrollment_date).format("DD-MMM-YYYY");
            if(actual_enrollment_date == all_call_logs[i].date){
              enrollments.push(element);
            }
        });
        // console.log("incentives data users",this.users);
        if(this.selected_team == 'SR'){
          let enrollments_greater_than_thirty = {
            recording_available: 0,
            total_ewb_calls: 0,
            incentives_approved: 0,
            unique_connected_calls: 0,
          };
          let enrollments_less_than_equals_thirty = {
            recording_available: 0,
            total_ewb_calls: 0,
            incentives_approved: 0,
            unique_connected_calls: 0,
          };
          this.users.day_wise_summary.forEach(incentive => {

            let date = Object.keys(incentive)[0];
            let summary_date = moment(date).format('DD-MMM-YYYY');

            if(all_call_logs[i].date == summary_date){
                console.log("incentives data hgdfgy", incentive[date]);
            // }
              incentive[date].forEach(summary => {
                console.log("incentives summary", summary);
                if(summary.days_classification == 'enrollments>30days'){
                    enrollments_greater_than_thirty = {
                      recording_available: summary.recording_available,
                      total_ewb_calls: summary.total_ewb_calls,
                      incentives_approved: summary.incentives_approved,
                      unique_connected_calls: summary.unique_connected_calls,
                    };
                  }
                  if(summary.days_classification == 'enrollments<=30days'){
                    enrollments_less_than_equals_thirty = {
                      recording_available: summary.recording_available,
                      total_ewb_calls: summary.total_ewb_calls,
                      incentives_approved: summary.incentives_approved,
                      unique_connected_calls: summary.unique_connected_calls,
                    };
                  }
              });
              // if(Object.keys(enrollments_greater_than_thirty).length !== 0){

              // }
              // if(Object.keys(enrollments_less_than_equals_thirty).length !== 0){

              // }

            }
            Object.assign(all_call_logs[i], { enrollments_greater_than_thirty : enrollments_greater_than_thirty})
            Object.assign(all_call_logs[i], { enrollments_less_than_equals_thirty : enrollments_less_than_equals_thirty})
          });
        }

        for (var y = 0; y < all_call_logs[i]["gmeet_data"].length; y++) {
          gmeet_duration_sec +=
                all_call_logs[i]["gmeet_data"][y].duration_seconds;
        }

        for (var j = 0; j < all_call_logs[i]["all_call_logs"].length; j++) {
          all_call_logs[i]["all_call_logs"][j].spoc_name =
            all_call_logs[i].spoc_name;
          if (
            all_call_logs[i]["all_call_logs"][j].contact_type == "B2C" ||
            all_call_logs[i]["all_call_logs"][j].contact_type == "B2BIR" ||
            all_call_logs[i]["all_call_logs"][j].contact_type == "B2BCR"
          ) {
            if (all_call_logs[i]["all_call_logs"][j].updated == 1) {
              business_calls_array.push(all_call_logs[i]["all_call_logs"][j]);
              call_duration_sec +=
                all_call_logs[i]["all_call_logs"][j].call_duration;
              total_calls++;
              if (
                all_call_logs[i]["all_call_logs"][j].directory === "INCOMING"
              ) {
                incoming_call_count++;
              } else if (
                all_call_logs[i]["all_call_logs"][j].directory === "OUTGOING"
              ) {
                outgoing_call_count++;
              }
              if (all_call_logs[i]["all_call_logs"][j].call_duration > 0) {
                connected_calls++;
                connected_calls_arr.push(all_call_logs[i]["all_call_logs"][j]);
              }
              if (all_call_logs[i]["all_call_logs"][j].call_duration >= 60 && all_call_logs[i]["all_call_logs"][j].contact_type == "B2C") {
                cm_connected_calls++;
                cm_connected_calls_arr.push(all_call_logs[i]["all_call_logs"][j]);
              }
              if (ewb_levels.includes(all_call_logs[i]["all_call_logs"][j].level)) {
                ewb_call_count++;
                ewb_arr.push(all_call_logs[i]["all_call_logs"][j]);
                if (all_call_logs[i]["all_call_logs"][j].call_duration >= 60) {
                  ewb_connected_call_count++;
                  ewb_connected_calls++;
                  ewb_connected_calls_arr.push(
                    all_call_logs[i]["all_call_logs"][j]
                  );
                }
                if (all_call_logs[i]["all_call_logs"][j].updated == 1) {
                  ewb_business_calls_array.push(
                    all_call_logs[i]["all_call_logs"][j]
                  );
                }
              } else {
                mwb_call_count++;
                mwb_arr.push(all_call_logs[i]["all_call_logs"][j]);
                if (all_call_logs[i]["all_call_logs"][j].call_duration >= 60) {
                  mwb_connected_call_count++;
                  mwb_connected_calls++;
                  mwb_connected_calls_arr.push(
                    all_call_logs[i]["all_call_logs"][j]
                  );
                }
                if (all_call_logs[i]["all_call_logs"][j].updated == 1) {
                  mwb_business_calls_array.push(
                    all_call_logs[i]["all_call_logs"][j]
                  );
                }
              }
              if (
                all_call_logs[i]["all_call_logs"][j].call_duration >= 1800 &&
                all_call_logs[i]["all_call_logs"][j].call_duration < 3600
              ) {
                call_30_60++;
                call_30_60_arr.push(
                    all_call_logs[i]["all_call_logs"][j]);
              } else if (
                all_call_logs[i]["all_call_logs"][j].call_duration >= 3600
              ) {
                call_gt_60++;
                call_gt_60_arr.push(
                    all_call_logs[i]["all_call_logs"][j]);
              }
            }
            // if (all_call_logs[i]["all_call_logs"][j].directory == "MISSED") {
            //   if (
            //     all_call_logs[i]["all_call_logs"][j].contact_type ===
            //       "untracked" ||
            //     all_call_logs[i]["all_call_logs"][j].contact_type === "B2C"
            //   ) {
            //     if (all_call_logs[i]["all_call_logs"][j].updated === 0) {
            //       missed_call_count++;
            //       missed_calls_arr.push(call);
            //     }
            //   }
            //   missed_call_count++;
            //   missed_calls_arr.push(all_call_logs[i]["all_call_logs"][j]);
            // }
          } else if (
            all_call_logs[i]["all_call_logs"][j].contact_type == "untracked"
          ) {
            if (
              all_call_logs[i]["all_call_logs"][j]["directory"] == "INCOMING"
            ) {
              untracked_in_calls_count++;
              untracked_in_calls_duration =
                untracked_in_calls_duration +
                all_call_logs[i]["all_call_logs"][j]["call_duration"];
            } else if (
              all_call_logs[i]["all_call_logs"][j]["directory"] == "OUTGOING"
            ) {
              untracked_out_calls_count++;
              untracked_out_calls_duration =
                untracked_out_calls_duration +
                all_call_logs[i]["all_call_logs"][j]["call_duration"];
            }
          } else {
            nuc_count++;
            nuc_arr.push(all_call_logs[i]["all_call_logs"][j]);
            nuc_duration =
              nuc_duration + all_call_logs[i]["all_call_logs"][j].call_duration;
          }
        }
        all_call_logs[i].all_call_logs.forEach((call) => {
          if (call.directory === "MISSED") {
            if (
              call.contact_type === "untracked" ||
              call.contact_type === "B2C"
            ) {
              if (call.updated === 0) {
                missed_call_count++;
                missed_calls_arr.push(call);
              }
            }
          }
        });
        var unique_calls_array = [
          ...new Set(business_calls_array.map((item) => item.person_id)),
        ];
        var ewb_unique_calls_array = [
          ...new Set(ewb_business_calls_array.map((item) => item.person_id)),
        ];
        var mwb_unique_calls_array = [
          ...new Set(mwb_business_calls_array.map((item) => item.person_id)),
        ];
        var call_duration_mins = Math.round(call_duration_sec / 60);
        var call_utilization = 0;
        if(this.selected_team == "SR"){
          call_utilization = Math.round((call_duration_mins * 100) / 300);
        }else{
          call_utilization = Math.round((call_duration_mins * 100) / 180);
        }
        var gmeet_duration_mins = Math.round(gmeet_duration_sec / 60);
        var gmeet_utilization = 0;
        if (this.selected_team == "SR") {
           gmeet_utilization = Math.round((gmeet_duration_mins * 100) / 300);
        } else {
          gmeet_utilization = Math.round((gmeet_duration_mins * 100) / 180);
        }

        // console.log("generated utilization");
        var visitor_utilization = 0;
        // console.log("passed");
        if (all_call_logs[i]["visit_engagements"].length > 0) {
          var first_visit_count = 0;
          var enrollment_visit_count = 0;
          var repeat_visit_count = 0;
          for (
            var k = 0;
            k < all_call_logs[i]["visit_engagements"].length;
            k++
          ) {
            if (
              all_call_logs[i]["visit_engagements"][k]["visit_status"] ===
              "First"
            ) {
              //12.5%
              first_visit_count++;
            } else if (
              all_call_logs[i]["visit_engagements"][k]["visit_status"] ===
              "Enrollment"
            ) {
              if (
                all_call_logs[i]["visit_engagements"][k]["visit_status"] ===
                  "Enrollment" &&
                all_call_logs[i]["visit_engagements"][k]["visit_count"] === 1
              ) {
                first_visit_count++;
              } else {
                enrollment_visit_count++;
              }
              //6.25%
              // enrollment_visit_count++;
            } else if (
              all_call_logs[i]["visit_engagements"][k]["visit_status"] ===
              "Repeat"
            ) {
              //3.125%
              repeat_visit_count++;
            }
          }
          visitor_utilization =
            first_visit_count * 12.5 +
            enrollment_visit_count * 6.25 +
            repeat_visit_count * 3.125;
        }
        training_utilization = this.getTrainingPercentage(
          all_call_logs[i]["training"]
        );
         all_call_logs[i].spoc_name = this.users.spoc_name;
        all_call_logs[i].call_duration_sec = call_duration_sec;
        all_call_logs[i].call_utilization = call_utilization ;
        all_call_logs[i].gmeet_utilization = gmeet_utilization ;
        all_call_logs[i].visitor_utilization = visitor_utilization + "%";
        all_call_logs[i].training_utilization = training_utilization + "%";
        all_call_logs[i].total_utilization =
          Math.round(call_utilization + visitor_utilization + gmeet_utilization) + "%";
        if (call_utilization + visitor_utilization + gmeet_utilization >= 100) {
          total_color = "success";
        } else if (
          call_utilization + visitor_utilization + gmeet_utilization >= 80 &&
          call_utilization + visitor_utilization + gmeet_utilization < 100
        ) {
          total_color = "warning";
        } else if (call_utilization + visitor_utilization + gmeet_utilization < 80) {
          total_color = "danger";
        }
        //
        cm_unique_connected_calls =[
          ...new Set(cm_connected_calls_arr.map((item) => item.last_ten_digits)),
        ];
        all_call_logs[i].unique_cm_connected_calls = cm_unique_connected_calls.length;
        all_call_logs[i].total_calls = total_calls;
        all_call_logs[i].enrollments = enrollments;
        all_call_logs[i].incoming_call_count = incoming_call_count;
        all_call_logs[i].outgoing_call_count = outgoing_call_count;
        all_call_logs[i].total_color = total_color;
        all_call_logs[i].mwb_connected_call_count = mwb_connected_call_count;
        all_call_logs[i].ewb_connected_call_count = ewb_connected_call_count;
        all_call_logs[i].total_unique_calls = unique_calls_array.length;
        all_call_logs[i].total_ewb_unique_calls = ewb_unique_calls_array.length;
        all_call_logs[i].total_mwb_unique_calls = mwb_unique_calls_array.length;
        all_call_logs[i].total_mwb_ewb_unique_calls =
          mwb_unique_calls_array.length + ewb_unique_calls_array.length;
        all_call_logs[i].total_duration = this.secondsToHms(call_duration_sec);
        all_call_logs[i].mwb_call_count = mwb_call_count;
        all_call_logs[i].connected_calls = connected_calls;
        all_call_logs[i].cm_connected_calls = cm_connected_calls;
        all_call_logs[i].connected_calls_arr = connected_calls_arr;
        all_call_logs[i].cm_connected_calls_arr = cm_connected_calls_arr;
        all_call_logs[i].ewb_connected_calls = ewb_connected_calls;
        all_call_logs[i].ewb_connected_calls_arr = ewb_connected_calls_arr;
        all_call_logs[i].mwb_connected_calls = mwb_connected_calls;
        all_call_logs[i].mwb_connected_calls_arr = mwb_connected_calls_arr;
        all_call_logs[i].mwb_arr = mwb_arr;
        all_call_logs[i].ewb_call_count = ewb_call_count;
        all_call_logs[i].ewb_arr = ewb_arr;
        all_call_logs[i].untracked_in_calls_count = untracked_in_calls_count;
        all_call_logs[i].untracked_in_calls_duration = this.secondsToHms(
          untracked_in_calls_duration
        );
        all_call_logs[i].untracked_out_calls_count = untracked_out_calls_count;
        all_call_logs[i].untracked_out_calls_duration = this.secondsToHms(
          untracked_out_calls_duration
        );
        all_call_logs[i].missed_call_count = missed_call_count;
        all_call_logs[i].missed_calls_arr = missed_calls_arr;
        all_call_logs[i].call_30_60 = call_30_60;
        all_call_logs[i].call_30_60_arr = call_30_60_arr;
        all_call_logs[i].call_gt_60 = call_gt_60;
        all_call_logs[i].call_gt_60_arr = call_gt_60_arr;
        all_call_logs[i].nuc_count = nuc_count;
        all_call_logs[i].nuc_arr = nuc_arr;
        all_call_logs[i].nuc_duration = this.secondsToHms(nuc_duration);
      }
      this.all_call_logs = all_call_logs;
      // console.log("calculated utilization data is ", this.all_call_logs);
      console.log("masterMerge", this.masterMerge);
      this.$vs.loading.close();
    },
    secondsToHms(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      return (
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2)
      );
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    getActivityUtilization() {
      let keys = Object.keys(this.activity)
          this.masterMerge.forEach((master) => {
            let activity_sum = 0
            keys.forEach((key) => {
              if (master.all_call_logs[0].user_id == key) {
                this.activity[key].forEach((user) => {
                  if (moment.unix(user.date).format('DD-MMM-YYYY') == master.date) {
                    activity_sum = user.activity_sum
                  }
                })
              }
            })
            var total_color = ""
            let visit_utilization = Number(master.visitor_utilization.replace(/%/g, ""));
            let total = master.call_utilization + activity_sum + visit_utilization
            master.total_utilization = Math.round(total)+'%'
            if (Math.round(total) >= 100) {
              total_color = "success";
            } else if (
              Math.round(total) >= 80 &&
              Math.round(total) < 100
            ) {
              total_color = "warning";
            } else if (Math.round(total) < 80) {
              total_color = "danger";
            }
            master.activity_sum = activity_sum;
            master.total_color = total_color;
          });
    }
  },
}
</script>

<style>
.popup-width1 .vs-popup {
  width: 1175px !important;
}
</style>
